import React, { Component, useState } from "react";

import Geosuggest from "react-geosuggest";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import Leaflet from "leaflet";

import Modal from "simple-react-modal";
import Table from "react-bootstrap/Table";
//import pin from "../../../../../../assets/icons/pin_small.png";
import pin from "../../../../../../assets/icons/pin_bandera.png";
import icon from "../../../../../../assets/my_location_marker.png";
import pinBig from "../../../../../../assets/icons/pin.png";
import mapStyles from "../../../../../../utils/mapStyle";
import Button from "../../../../../../shared/components/Button";
import { helpers } from "../../../../../../utils/helpers";
import "./styles.css";
import axios from "axios";

const { compose, withProps, withStateHandlers } = require("recompose");
const googleScriptSrc =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyAa6MJtEisuiJW107yTrKiYihHX00iu0bg&v=3.exp&libraries=geometry,drawing,places";
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

const defaultMapOptions = {
  styles: mapStyles
};
//const [token, setToken] = useState(sessionStorage.getItem("token"));

class LeafletMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMarker: null,

      center: { lat: -34.578585, lng: -58.564604 },
      user: null,
      zoom: 12,
      address: "",
      showModal: false,
      modalMessage: "",
      agencies: [],
      token: sessionStorage.getItem("token"),
      paradas: ""
    };
  }

  componentDidMount() {
    this._getPuntoRecoleccion(this.state.token);
  }

  _getPuntoRecoleccion = token => {
    let buscarParadas = process.env.REACT_APP_API_URL + `/api/buscar_paradas`;
    console.log('console.log',buscarParadas);
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      //url: "https://ws.viatesting.com.ar/enc/paradasmovil/search",
      url: buscarParadas,

      headers: {
        "X-API-KEY": token
      }
    };

    axios(config)
      .then(response => {
        console.log('response',response)
        if (response.data.length > 0) {
          this.setState({
            paradas: response.data
          });
          // console.log(JSON.stringify(response.data));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  _handleInput = (prop, value) => {
    this.setState({ [prop]: value });
  };

  render() {
    // if (!(window.google && window.google.maps)) {
    //   setTimeout(() => {
    //     this.setState({ address: "" });
    //   }, 1000);
    // }

    let myIcon = Leaflet.icon({
      iconUrl: icon,
      popupAnchor: [-3, -76]
    });

    let marker = Leaflet.icon({
      iconUrl: pin,
      popupAnchor: [0, -18],
      iconSize: [36, 36],
      iconAnchor: [18, 18]
    });

    let cont = 1;
    let posArr = [];
    let poliArr = [];

    const { agencies } = this.state;
    const { paradas } = this.state;

    return (
      <section className="home-map-pickup">
        <div class="container-banner">
          <div class="row">
            <div class="col-md-4 div-container-table">
              <div class="container-table">
                <table>
                  <thead>
                    <tr class="special-border1">
                      <th rowspan="2" class="th-left">
                        PARADA
                      </th>
                      <th rowspan="2" class="th-left">
                        DIRECCIÓN
                      </th>
                      <th colspan="2" class="special-border2">
                        HORARIOS
                      </th>
                    </tr>
                    <tr>
                      <th>Desde</th>
                      <th class="special-border1">Hasta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paradas &&
                      paradas.map(paradas => {
                        return (
                          <tr>
                            <td>{paradas.Descripcion}</td>
                            <td>{paradas.Direccion}</td>
                            <td>{paradas.HorarioDesde}</td>
                            <td class="special-border1">
                              {paradas.HorarioHasta}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <p class="avisos">
                Servicio disponible días hábiles de Lunes a Viernes.
              </p>
            </div>
            <div class="col-md-8">
              <Map
                center={this.state.center}
                style={{ height: 450, zIndex: 1 }}
                zoom={this.state.zoom}
              >
                <TileLayer
                  url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                  maxZoom={16}
                  minZoom={5}
                />

                <MarkerClusterGroup className="markercluster-map">
                  {paradas &&
                    paradas.map(paradas => {
                      let descripcion = paradas.Descripcion;
                      let direccion = paradas.Direccion;
                      let latitud = paradas.Latitud;
                      let longitud = paradas.Longitud;
                      let position = {
                        lat: latitud,
                        lng: longitud
                      };

                      return (
                        <Marker position={position} icon={marker}>
                          <Popup>
                            <div className="info-box">
                              <h3>{descripcion}</h3>
                              <p>{direccion}</p>
                            </div>
                          </Popup>
                        </Marker>
                      );
                    })}
                </MarkerClusterGroup>
              </Map>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i
              className={"material-icons"}
              onClick={() => this.setState({ showModal: false })}
            >
              close
            </i>
          </div>
          <div className={"modal-body"}>
            {this.state.modalMessage}
            <div>
              <Button
                className="quote"
                onClick={() => this.setState({ showModal: false })}
              >
                Entendido
              </Button>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}

export default LeafletMap;
